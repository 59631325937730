.site-error {
  margin-top: 190px;
}

.site-error h1 {
  font-size: 8em;
  line-height: 0.7;
}

@media screen and (max-width: 767px) {
  .site-error {
    margin-top: 60px;
  }
  .site-error h1 {
    font-size: 5em;
    line-height: 0.7;
  }
}
